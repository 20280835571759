@font-face {
  font-family: 'TWK Lausanne';
  font-style: normal;
  font-weight: 150;
  font-display: swap;
  src: url(../fonts/TWKLausanne-150.woff2) format('woff2');
}

@font-face {
  font-family: 'TWK Lausanne';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/TWKLausanne-300.woff2) format('woff2');
}

@font-face {
  font-family: 'TWK Lausanne';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/TWKLausanne-600.woff2) format('woff2');
}

@font-face {
  font-family: 'TWK Lausanne';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/TWKLausanne-600.woff2) format('woff2');
}

@import "variables";

// scss-docs-start import-stack
// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
// @import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
// @import "~bootstrap/scss/navbar";
// @import "~bootstrap/scss/card";
// @import "~bootstrap/scss/accordion";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
// @import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
// @import "~bootstrap/scss/offcanvas";
// @import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack

@import "bootswatch";

svg {
  vertical-align: -.125em;
}