@import "./assets/sass/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.logo {
  z-index: -1;
  opacity: .25;
  top: -10rem;
  right: -10rem;
  bottom: (-10rem + ($font-size-base * $line-height-base));

  svg {
    height: 100%;

    @media (prefers-reduced-motion: no-preference) {
      animation: spin infinite 20s linear;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.status {
  font-size: $font-size-base * .675;
}

.grecaptcha-badge {
  bottom: 100px !important;
}